'use client'
import { useEffect } from 'react'

const InstagramEmbed = ({
  url,
  title,
  description,
}: {
  url: string
  title: string
  description: string
}) => {
  useEffect(() => {
    const script = document.createElement('script')
    script.src = '//www.instagram.com/embed.js'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [url])

  return (
    <div className="instagram-embed w-[96%] m-auto mt-2">
      <div className="instagram-embed-text mb-4 font-bold">{title}</div>
      {description != '' && (
        <div className="instagram-embed-description mb-4">{description}</div>
      )}
      {url != '' && (
        <blockquote
          className="instagram-media"
          data-instgrm-captioned
          data-instgrm-permalink={url}
          style={{
            background: '#FFF',
            border: 0,
            borderRadius: '3px',
            boxShadow:
              '0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15)',
            margin: '1px',
            maxWidth: '540px',
            minWidth: '326px',
            padding: 0,
            width: 'calc(100% - 2px)',
          }}
        />
      )}
    </div>
  )
}

export default InstagramEmbed

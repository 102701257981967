'use client'
import { categoryType } from '@/lib/category'
import { serviceType } from '@/lib/service'
import Link from 'next/link'
import { usePathname, useSearchParams } from 'next/navigation'

type ParamsSubcategoryProps = {
  service: serviceType
  categories: categoryType[]
  title?: string
  style?: string
  center: boolean
}

export const ParamsCategory: React.FC<ParamsSubcategoryProps> = ({
  service,
  title,
  categories,
  center,
}) => {
  const currentPath = usePathname()
  // searchParam
  let hasCategoryId = false
  let hasSubcategoryId = false
  let hasTagId = false
  const searchParams = useSearchParams()
  const categoryId = searchParams.get('category_id')
  if (categoryId) {
    hasCategoryId = true
  }
  const subcategoryId = searchParams.get('subcategory_id')
  if (subcategoryId) {
    hasSubcategoryId = true
  }
  const tagId = searchParams.get('tag_id')
  if (tagId) {
    hasTagId = true
  }
  let path = currentPath
  if (hasCategoryId && hasSubcategoryId && hasTagId) {
    path = `${currentPath}?category_id=${categoryId}&subcategory_id=${subcategoryId}&tag_id=${tagId}`
  } else if (hasCategoryId && hasSubcategoryId) {
    path = `${currentPath}?category_id=${categoryId}&subcategory_id=${subcategoryId}`
  } else if (hasCategoryId && hasTagId) {
    path = `${currentPath}?category_id=${categoryId}&tag_id=${tagId}`
  } else if (hasSubcategoryId && hasTagId) {
    path = `${currentPath}?subcategory_id=${subcategoryId}&tag_id=${tagId}`
  } else if (hasCategoryId) {
    path = `${currentPath}?category_id=${categoryId}`
  } else if (hasSubcategoryId) {
    path = `${currentPath}?subcategory_id=${subcategoryId}`
  } else if (hasTagId) {
    path = `${currentPath}?tag_id=${tagId}`
  }
  return (
    <>
      <div className="text-center mb-1">
        <h2 className="text-lg font-bold">
          {title ? title : 'カテゴリで絞る'}
        </h2>
      </div>
      <div
        className={
          'inline-block overflow-x-auto flex sm:justify-center' +
          (center ? ' justify-center' : '')
        }
      >
        {categories.map((v, index) => (
          <Link
            key={index}
            href={
              path.includes('category_id')
                ? path.replace(
                    /category_id=[0-9]+/,
                    `category_id=${v.category_id}`,
                  )
                : path.includes('?')
                  ? `${path}&category_id=${v.category_id}`
                  : `${path}?category_id=${v.category_id}`
            }
            style={{
              backgroundColor:
                v.category_id === Number(categoryId)
                  ? service.main_color
                  : '#efefef',
              color: v.category_id === Number(categoryId) ? '#fff' : '',
              cursor: 'pointer',
              display: 'inline-block',
              fontSize: '13px',
              fontWeight: 600,
              borderRadius: '21px',
              padding: '7px 16px',
              marginBottom: '12px',
              marginRight: '8px',
            }}
          >
            {v.name}
          </Link>
        ))}
      </div>
    </>
  )
}

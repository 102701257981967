'use client'
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '@/components/ui/pagination'
import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect, useState } from 'react'

type PaginationProps = {
  pagination: {
    total: number
    limit: number
    offset: number
  }
}

export const PaginationBlock: React.FC<PaginationProps> = ({ pagination }) => {
  const [currentHref, setCurrentHref] = useState<string>('')
  const currentPage =
    pagination.offset % pagination.limit == 0
      ? pagination.offset / pagination.limit
      : Math.floor(pagination.offset / pagination.limit) + 1
  const lastPage = Math.floor(pagination.total / pagination.limit) + 1
  const pathName = usePathname()
  const searchParams = useSearchParams()
  useEffect(() => {
    let sp = searchParams.toString()
    if (sp == '') sp += 'page=1'
    if (sp.includes('=') && !sp.includes('page')) sp += '&page=1'
    setCurrentHref(`${pathName}?${sp}`)
  }, [pathName, searchParams])
  return (
    <Pagination>
      <PaginationContent>
        {currentPage != 1 && (
          <PaginationItem>
            <PaginationPrevious
              href={currentHref.replace(
                `page=${currentPage}`,
                `page=${currentPage - 1}`,
              )}
            />
          </PaginationItem>
        )}
        <PaginationItem>
          <PaginationLink
            href={currentHref.replace(`page=${currentPage}`, 'page=1')}
            isActive={currentPage == 1}
            className={currentPage == 1 ? 'bg-white' : ''}
          >
            1
          </PaginationLink>
        </PaginationItem>
        {currentPage == 2 && lastPage != 2 && (
          <PaginationItem>
            <PaginationLink
              href={currentHref.replace(`page=${currentPage}`, 'page=2')}
              isActive={currentPage == 2}
              className="bg-white"
            >
              2
            </PaginationLink>
          </PaginationItem>
        )}
        {lastPage > 2 && (
          <PaginationItem>
            <PaginationEllipsis />
          </PaginationItem>
        )}
        {lastPage > 4 && currentPage > 2 && currentPage != lastPage - 1 && (
          <PaginationItem>
            <PaginationLink href={currentHref} isActive>
              {currentPage}
            </PaginationLink>
          </PaginationItem>
        )}
        {currentPage == lastPage - 1 && currentPage > 2 && (
          <PaginationItem>
            <PaginationLink
              href={currentHref.replace(
                `page=${currentPage}`,
                `page=${currentPage}`,
              )}
              isActive
              className="bg-white"
            >
              {lastPage - 1}
            </PaginationLink>
          </PaginationItem>
        )}
        <PaginationItem>
          <PaginationLink
            href={currentHref.replace(
              `page=${currentPage}`,
              `page=${lastPage}`,
            )}
            isActive={currentPage == lastPage}
            className={currentPage == lastPage ? 'bg-white' : ''}
          >
            {lastPage}
          </PaginationLink>
        </PaginationItem>
        {pagination.total != pagination.offset && (
          <PaginationItem>
            <PaginationNext
              href={currentHref.replace(
                `page=${currentPage}`,
                `page=${currentPage + 1}`,
              )}
            />
          </PaginationItem>
        )}
      </PaginationContent>
    </Pagination>
  )
}
